<template>
  <b-row>
    <OffersListAddNew
      @refetch-data="refetchData"
      @hideModal="isAddNewUserModalActive = false"
      :isAddNewUserModalActive="isAddNewUserModalActive"
    />
    <b-col md="12">
      <h1 class="mb-2">Предложения</h1>
    </b-col>
    <b-col md="12">
      <OffersListFilters
        @filterByVisible="filterByVisible"
        @filterByGiftable="filterByGiftable"
        @filterByStatus="filterByStatus"
        @filterByDateFrom="filterByDateFrom"
        @filterByDateTo="filterByDateTo"
      />
      <b-card no-body class="mb-0">
        <div class="px-2 py-1">
          <b-row>
            <b-col md="12">
              <div class="d-flex res-flex-column justify-content-between">
                <div class="d-flex res-align-items-start res-flex-column">
                  <div class="d-flex align-items-center">
                    <span class="text-nowrap">Отображать по:</span>
                    <v-select
                      v-model="pageLength"
                      :options="pages"
                      :clearable="false"
                      @input="handlePageLengthChange"
                      class="per-page-selector d-inline-block mx-50"
                    />
                  </div>
                  <div
                    class="res-w-100"
                    v-if="selectedRows && selectedRows.length > 0"
                  >
                    <b-dropdown
                      class="res-mt-2 res-w-100"
                      id="dropdown-1"
                      right
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      text="Редактировать выбранные"
                      variant="primary"
                      menu-class="w-100"
                    >
                      <b-dropdown-item
                        v-for="(item, i) in offerStatuses"
                        :key="i"
                        @click.prevent="editSelectedRows(item)"
                      >
                        {{ item.title }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>

                <div
                  class="
                    d-flex
                    res-flex-column res-users-list-search
                    align-items-center
                  "
                >
                  <div class="mr-2 res-m-0 res-w-100">
                    <!-- basic search -->
                    <b-input-group
                      class="input-group-merge res-w-100 mr-2 res-mt-2"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        class="res-w-100"
                        @input="handleSearch"
                        v-model="searchTerm"
                        placeholder="Поиск"
                      />
                    </b-input-group>
                  </div>

                  <b-button
                    class="res-mt-2 res-w-100"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="isAddNewUserModalActive = true"
                  >
                    <span class="text-nowrap">Добавить предложение</span>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <vue-good-table
          class="position-relative"
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="false"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
          @on-selected-rows-change="onSelectChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: titleRu -->
            <span v-if="props.column.field === 'titleRu'" class="text-nowrap">
              <router-link
                :to="{
                  name: 'offer-edit',
                  params: { id: props.row.id },
                }"
              >
                <!-- <b-avatar
                  size="md"
                  :src="props.row.offerImageList[0].url"
                  class="mr-1 badge-light-primary"
                /> -->
                <span class="text-nowrap font-weight-bold text-nowrap">
                  {{ props.row.titleRu }}
                </span>
              </router-link>
            </span>

            <!-- Column: dateCreated -->
            <span
              v-else-if="props.column.field === 'dateCreated'"
              class="text-nowrap"
            >
              <span>
                {{ props.formattedRow.dateCreated | formatDate }}
              </span>
              <span v-if="props.row.dateCreated == null"> Не указано </span>
            </span>

            <!-- Column: dateModified -->
            <span
              v-else-if="props.column.field === 'dateModified'"
              class="text-nowrap"
            >
              <span>
                {{ props.formattedRow.dateModified | formatDate }}
              </span>
              <span v-if="props.row.dateModified == null"> Не указано </span>
            </span>

            <!-- Column: finishDate -->
            <span
              v-else-if="props.column.field === 'finishDate'"
              class="text-nowrap"
            >
              <span>
                {{ props.formattedRow.finishDate | formatDate }}
              </span>
              <span v-if="props.row.finishDate == null"> Не указано </span>
            </span>

            <!-- Column: finishDate -->
            <span
              v-else-if="props.column.field === 'daysCountTillTheEnd'"
              class="text-nowrap"
            >
              <span> {{ props.formattedRow.daysCountTillTheEnd }} дней </span>
            </span>

            <!-- Column: isVisible -->
            <span
              v-else-if="props.column.field === 'isVisible'"
              class="text-nowrap"
            >
              <span v-if="props.row.isVisible === 1"> Видимый </span>
              <span v-else> Скрытый </span>
            </span>

            <!-- Column: isGiftable -->
            <span
              v-else-if="props.column.field === 'isGiftable'"
              class="text-nowrap"
            >
              <span v-if="props.row.isGiftable === 1"> Подарочный </span>
              <span v-else> Неподарочный </span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'offerState'">
              <b-badge :variant="statusVariant(props.row.offerState).color">
                {{ statusVariant(props.row.offerState).text }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :to="{
                      name: 'offer-edit',
                      params: { id: props.row.id },
                    }"
                  >
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Изменить</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="deleteOffer(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Удалить</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom">
            <div
              class="d-flex justify-content-between px-2 pb-2 pt-1 flex-wrap"
            >
              <!-- page totalCount -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-muted">Общее кол-во: {{ totalCount }}</span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalCount"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import OffersListAddNew from "./OffersListAddNew.vue";
import OffersListFilters from "./OffersListFilters.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios.js";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    OffersListAddNew,
    OffersListFilters,
    ToastificationContent,
    Ripple,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
  },
  directives: {
    Ripple,
  },
  computed: {
    todayDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd + " 59:00:00";
    },
    statusVariant() {
      const statusVariants = {
        /* eslint-disable key-spacing */
        ACTIVE: {
          color: "light-success",
          text: "Активный",
        },
        DELETED: {
          color: "light-danger",
          text: "Удаленный",
        },
        EXPIRED: {
          color: "light-warning",
          text: "Истекший",
        },
        SUSPENDED: {
          color: "light-secondary",
          text: "Приостановленный",
        },
        /* eslint-enable key-spacing */
      };

      return (status) => statusVariants[status];
    },
  },
  setup() {
    return {
      avatarText,
    };
  },
  data() {
    return {
      dateFrom: "",
      dateTo: "",
      selectedRows: null,
      isAddNewUserModalActive: false,
      pageLength: 10,
      currentPage: 1,
      totalCount: null,
      sortField: null,
      field: null,
      fieldValue: null,
      pages: ["5", "10", "15", "20"],
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Название",
          field: "titleRu",
        },
        {
          label: "Скидка",
          field: "discount",
        },
        {
          label: "Дата создания",
          field: "dateCreated",
        },
        {
          label: "Дата редактирования",
          field: "dateModified",
        },
        {
          label: "Дата окончания",
          field: "finishDate",
        },
        {
          label: "Осталось",
          field: "daysCountTillTheEnd",
        },
        {
          label: "Видимый/Скрытый",
          field: "isVisible",
        },
        {
          label: "Подарочный/Неподарочный",
          field: "isGiftable",
        },
        {
          label: "Статус",
          field: "offerState",
        },
        {
          label: "Действие",
          field: "action",
        },
      ],
      offerStatuses: [
        {
          title: "Активировать",
          value: "ACTIVE",
        },
        {
          title: "Приостановить",
          value: "SUSPENDED",
        },
        {
          title: "Удалить",
          value: "DELETED",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const response = await axiosIns
        .get(`offer/getAll`, {
          params: {
            page: this.currentPage,
            size: this.pageLength,
            sort: this.sortField,
            field: this.field,
            fieldValue: this.fieldValue,
          },
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      this.rows = response.data.data.offers;
      this.totalCount = response.data.data.totalCount;
    },
    async refetchData() {
      this.sort = "";
      this.field = "";
      this.fieldValue = "";
      this.getData();
    },
    handleSearch(searching) {
      this.field = "titleRu";
      this.fieldValue = searching;
      this.getData();
    },
    async handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    async handlePageLengthChange(active) {
      this.pageLength = active;
      this.getData();
    },
    onSortChange(params) {
      if (params[0].field !== "action") {
        this.sortField = params[0].field + "," + params[0].type;
        this.getData();
      }
    },
    filterByVisible(isVisible) {
      this.field = "isVisible";
      this.fieldValue = isVisible;
      this.getData();
    },
    filterByGiftable(isGiftable) {
      this.field = "isGiftable";
      this.fieldValue = isGiftable;
      this.getData();
    },
    filterByStatus(status) {
      this.field = "offerState";
      this.fieldValue = status;
      this.getData();
    },
    filterByDateFrom(date) {
      this.dateFrom = date;
      if (date.length) {
        this.dateFrom = this.dateFrom + " 00:00:00";
      }

      this.filterDates();
    },
    filterByDateTo(date) {
      this.dateTo = date;
      if (date.length) {
        this.dateTo = this.dateTo + " 59:00:00";
      }

      this.filterDates();
    },

    filterDates() {
      if (this.dateFrom.length === 0 && this.dateTo.length === 0) {
        this.field = "dateCreated";
        this.fieldValue = "";
        this.getData();
      }

      if (this.dateFrom.length && this.dateTo.length === 0) {
        this.field = "dateCreated";
        this.fieldValue = this.dateFrom + ";" + this.todayDate;
        this.getData();
      }

      if (this.dateFrom.length === 0 && this.dateTo.length) {
        this.field = "dateCreated";
        this.fieldValue = "2000-01-01 00:00:00" + ";" + this.dateTo;
        this.getData();
      }

      if (this.dateFrom.length && this.dateTo.length) {
        this.field = "dateCreated";
        this.fieldValue = this.dateFrom + ";" + this.dateTo;
        this.getData();
      }
    },

    onSelectChange(params) {
      const selectedRowsId = params.selectedRows.map(function (item) {
        return item.id;
      });
      this.selectedRows = selectedRowsId;
    },
    async editSelectedRows(item) {
      axiosIns
        .post("offer/change-offers-state", {
          offerList: this.selectedRows,
          offerState: item.value,
        })
        .then((response) => {
          if (response.status == 200) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Успешно`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Статус предложений был успешно изменен!`,
                },
              },
              {
                position: "top-right",
              }
            );
            this.getData();
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },

    async deleteOffer(id) {
      this.$bvModal
        .msgBoxConfirm("Вы уверены что хотите удалить?", {
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: "Удалить",
          cancelTitle: "Отмена",
        })
        .then((value) => {
          if (value === true) {
            const selectedOffer = [];
            selectedOffer.push(id);
            axiosIns
              .post("offer/change-offers-state", {
                offerList: selectedOffer,
                offerState: "DELETED",
              })
              .then((response) => {
                if (response.status == 200) {
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: `Успешно`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Предложение было успешно удалено!`,
                      },
                    },
                    {
                      position: "top-right",
                    }
                  );
                  this.getData();
                }
              })
              .catch((error) => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Ошибка`,
                      icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                      variant: "danger",
                      text: error.response.data.errorMessage,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                }
              });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.per-page-selector {
  min-width: 77px;
}
</style>
