<template>
  <b-modal
    id="modal-center"
    @change="hideModal"
    centered
    v-model="isAddNewUserModalActive"
    title="Добавить предложение"
    @show="showModal"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-tabs pills nav-class="">
              <b-tab title="RU" active>
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Название"
                      rules="required"
                    >
                      <b-form-group
                        label="Название"
                        label-for="new-merch-title-ru"
                      >
                        <b-form-input
                          :state="errors.length > 0 ? false : null"
                          v-model="titleRu"
                          id="new-merch-title-ru"
                          placeholder="Название"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Описание"
                      rules="required"
                    >
                      <b-form-group
                        label="Описание"
                        label-for="new-merch-desc-ru"
                      >
                        <b-form-textarea
                          id="new-merch-desc-ru"
                          v-model="descriptionRu"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Описание"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="UZ">
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Название"
                      rules="required"
                    >
                      <b-form-group
                        label="Название"
                        label-for="new-merch-title-uz"
                      >
                        <b-form-input
                          :state="errors.length > 0 ? false : null"
                          v-model="titleUz"
                          id="new-merch-title-uz"
                          placeholder="Название"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Описание"
                      rules="required"
                    >
                      <b-form-group
                        label="Описание"
                        label-for="new-merch-desc-uz"
                      >
                        <b-form-textarea
                          id="new-merch-desc-uz"
                          v-model="descriptionUz"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Описание"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="En">
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Название"
                      rules="required"
                    >
                      <b-form-group
                        label="Название"
                        label-for="new-merch-title-en"
                      >
                        <b-form-input
                          :state="errors.length > 0 ? false : null"
                          v-model="titleEn"
                          id="new-merch-title-en"
                          placeholder="Название"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Описание"
                      rules="required"
                    >
                      <b-form-group
                        label="Описание EN"
                        label-for="new-merch-desc-en"
                      >
                        <b-form-textarea
                          id="new-merch-desc-en"
                          v-model="descriptionEn"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Описание"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-col>

          <b-col md="12">
            <b-form-group label="Изображения" label-for="new-offer-imgs">
              <b-form-file
                ref="file"
                multiple
                id="new-offer-imgs"
                v-model="asd"
                @change="handleFileUpload($event)"
                placeholder="Выберите изображения..."
                accept="image/png, image/jpeg, image/tiff"
                drop-placeholder="Drop file here..."
              />
              <div>
                <small class="text-danger" v-if="imageRequired === 1"
                  >Поле Лого поддерживает только файлы в формате .jpg, .png,
                  .tiff</small
                >
              </div>
              <span class="sm-size"
                >(Поддерживаются файлы в формате .jpg, .png, .tiff)
              </span>
              <!-- <b-card-text class="my-1">
                Выбранный файл: <strong>{{ file ? file.name : "" }}</strong>
              </b-card-text> -->
            </b-form-group>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Скидка"
              rules="required"
            >
              <b-form-group label="Скидка" label-for="new-offer-discount">
                <b-input-group prepend="%" class="input-group-merge">
                  <b-form-input
                    id="new-offer-discount"
                    v-model="discount"
                    type="number"
                    @input="checkDiscount"
                    v-mask="'###'"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Введите скидку"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Дата окончания"
              rules="required"
            >
              <b-form-group
                label="Дата окончания"
                label-for="new-offer-expired-date"
              >
                <flat-pickr
                  v-model="dateExpired"
                  id="new-offer-expired-date"
                  placeholder="Выберите дату окончания"
                  class="form-control"
                  :config="datePickerConfig"
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Тип"
              rules="required"
            >
              <b-form-group label="Тип" label-for="new-offer-type">
                <v-select
                  id="new-offer-type"
                  placeholder="Выберите тип предложения"
                  v-model="offerType"
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="offerTypes"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Мерчант"
              rules="required"
            >
              <b-form-group label="Мерчант" label-for="new-offer-merchant">
                <v-select
                  id="new-offer-merchant"
                  placeholder="Введите название мерчанта"
                  v-model="selectedMerchant"
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  @search="getMerchants"
                  @input="getBranches"
                  :options="sortedMerchants"
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Филиалы"
              rules="required"
            >
              <b-form-group
                label="Филиалы"
                label-for="new-offer-merchant-branch"
              >
                <v-select
                  id="new-offer-merchant-branch"
                  placeholder="Введите название филиала"
                  v-model="selectedBranches"
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  multiple
                  :options="sortedBranches"
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer="{ cancel }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        type="reset"
        class="res-w-100"
        @click="cancel()"
      >
        Отмена
      </b-button>
      <b-button
        class="res-mt-2 res-w-100"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click.prevent="handleSubmit"
      >
        <span class="text-nowrap">Добавить</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormFile,
  BRow,
  BCol,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BButton,
  BTab,
  BTabs,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, max_value } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { Russian } from "flatpickr/dist/l10n/ru";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    flatPickr,
    BInputGroup,
    BInputGroupPrepend,
    BTab,
    BTabs,
    BFormFile,
    BRow,
    BCol,
    ToastificationContent,
    BFormTextarea,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddNewUserModalActive: {
      type: Boolean,
      required: true,
    },
  },
  computed: {},
  data() {
    return {
      datePickerConfig: {
        altInput: true,
        altFormat: "j F, Y",
        dateFormat: "U",
        locale: Russian,
      },
      required,
      max_value,

      asd: null,
      imageRequired: 0,

      maxlength: 3,
      titleRu: null,
      titleUz: null,
      titleEn: null,
      descriptionRu: null,
      descriptionUz: null,
      descriptionEn: null,
      file: null,
      discount: null,
      dateExpired: null,
      offerImages: [],
      selectedBranches: [],
      sortedBranches: [],
      selectedMerchant: null,
      sortedMerchants: null,
      offerType: {
        title: "Сертификат",
        value: "CERTIFICATE",
      },
      offerTypes: [
        {
          title: "Сертификат",
          value: "CERTIFICATE",
        },
        {
          title: "Информационное",
          value: "INFORMATION",
        },
        {
          title: "Ваучер",
          value: "VAUCHER",
        },
        {
          title: "Спец. кэшбэк",
          value: "SPECIAL_CASHBACK",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    showModal() {
      if (this.sortedMerchants === null) {
        this.getMerchants();
      } else {
      }
    },
    async getBranches() {
      const response = await axiosIns.get("branches/getAll/by-vendorId", {
        params: {
          vendorId: this.selectedMerchant.id,
        },
      });
      this.sortedBranches = response.data.data;
    },
    async getMerchants(search) {
      const response = await axiosIns
        .get(`vendor/all-active`, {
          params: {
            page: 1,
            size: 20,
            field: "name",
            fieldValue: search,
          },
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      this.sortedMerchants = response.data.data.vendors;
    },
    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success && this.imageRequired === 2) {
          const selectedBranchesId = this.selectedBranches.map(function (item) {
            return item.id;
          });
          const date = Number(this.dateExpired);
          const numericDiscount = Number(this.discount);

          axiosIns
            .post("offer/create", {
              vendorId: this.selectedMerchant.id,
              branchIdList: selectedBranchesId,
              offerForCreateDto: {
                discountPercentAmount: numericDiscount,
                titleRu: this.titleRu,
                titleUz: this.titleUz,
                titleEn: this.titleEn,
                descriptionRu: this.descriptionRu,
                descriptionUz: this.descriptionUz,
                descriptionEn: this.descriptionEn,
                dateExpired: date,
                offerType: this.offerType.value,
                offerImages: this.offerImages,
              },
              isVisible: 1,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Мерчант был успешно создан!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.hideModal();
              this.$emit("refetch-data");
              setTimeout(() => {
                this.resetForm();
              }, 200);
            });
        }
      });
    },
    async handleFileUpload(event) {
      this.offerImages = [];
      this.asd = event.target.files;
      for (var i = 0; i < this.asd.length; i++) {
        if (
          this.asd[i].type != "image/jpg" &&
          this.asd[i].type != "image/jpeg" &&
          this.asd[i].type != "image/png" &&
          this.asd[i].type != "image/tiff"
        ) {
          this.asd = [];
          this.imageRequired = 1;
          return false;
        }
        this.imageRequired = 2;
        const formData = new FormData();
        formData.append("logo", this.asd[i]);
        const response = await axiosIns.post(
          "image/upload/offers",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.offerImages.push(response.data.data.imageUrl);
      }
    },
    /* async submitFiles() {
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        const formData = new FormData();
        formData.append("logo", this.$refs.file.files[i]);
        const response = await axiosIns.post("image/upload/offers", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.offerImages.push(response.data.data.imageUrl);
      }
    }, */
    checkDiscount() {
      if (this.discount > 100) {
        this.discount = 100;
      }
    },
    hideModal() {
      this.$emit("hideModal");
    },
    resetForm() {
      this.titleRu = null;
      this.titleUz = null;
      this.titleEn = null;
      this.descriptionRu = null;
      this.descriptionUz = null;
      this.descriptionEn = null;
      this.file = null;
      this.selectedMerchant = null;
      this.selectedBranches = [];
      this.offerImages = [];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
