var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-center","centered":"","title":"Добавить предложение"},on:{"change":_vm.hideModal,"show":_vm.showModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"res-w-100",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){return cancel()}}},[_vm._v(" Отмена ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"res-mt-2 res-w-100",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Добавить")])])]}}]),model:{value:(_vm.isAddNewUserModalActive),callback:function ($$v) {_vm.isAddNewUserModalActive=$$v},expression:"isAddNewUserModalActive"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-tabs',{attrs:{"pills":"","nav-class":""}},[_c('b-tab',{attrs:{"title":"RU","active":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название","label-for":"new-merch-title-ru"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-merch-title-ru","placeholder":"Название"},model:{value:(_vm.titleRu),callback:function ($$v) {_vm.titleRu=$$v},expression:"titleRu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Описание","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание","label-for":"new-merch-desc-ru"}},[_c('b-form-textarea',{attrs:{"id":"new-merch-desc-ru","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"3"},model:{value:(_vm.descriptionRu),callback:function ($$v) {_vm.descriptionRu=$$v},expression:"descriptionRu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-tab',{attrs:{"title":"UZ"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название","label-for":"new-merch-title-uz"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-merch-title-uz","placeholder":"Название"},model:{value:(_vm.titleUz),callback:function ($$v) {_vm.titleUz=$$v},expression:"titleUz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Описание","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание","label-for":"new-merch-desc-uz"}},[_c('b-form-textarea',{attrs:{"id":"new-merch-desc-uz","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"3"},model:{value:(_vm.descriptionUz),callback:function ($$v) {_vm.descriptionUz=$$v},expression:"descriptionUz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-tab',{attrs:{"title":"En"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название","label-for":"new-merch-title-en"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-merch-title-en","placeholder":"Название"},model:{value:(_vm.titleEn),callback:function ($$v) {_vm.titleEn=$$v},expression:"titleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Описание","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание EN","label-for":"new-merch-desc-en"}},[_c('b-form-textarea',{attrs:{"id":"new-merch-desc-en","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"3"},model:{value:(_vm.descriptionEn),callback:function ($$v) {_vm.descriptionEn=$$v},expression:"descriptionEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Изображения","label-for":"new-offer-imgs"}},[_c('b-form-file',{ref:"file",attrs:{"multiple":"","id":"new-offer-imgs","placeholder":"Выберите изображения...","accept":"image/png, image/jpeg, image/tiff","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.handleFileUpload($event)}},model:{value:(_vm.asd),callback:function ($$v) {_vm.asd=$$v},expression:"asd"}}),_c('div',[(_vm.imageRequired === 1)?_c('small',{staticClass:"text-danger"},[_vm._v("Поле Лого поддерживает только файлы в формате .jpg, .png, .tiff")]):_vm._e()]),_c('span',{staticClass:"sm-size"},[_vm._v("(Поддерживаются файлы в формате .jpg, .png, .tiff) ")])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Скидка","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Скидка","label-for":"new-offer-discount"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"%"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"id":"new-offer-discount","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Введите скидку"},on:{"input":_vm.checkDiscount},model:{value:(_vm.discount),callback:function ($$v) {_vm.discount=$$v},expression:"discount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Дата окончания","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Дата окончания","label-for":"new-offer-expired-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"new-offer-expired-date","placeholder":"Выберите дату окончания","config":_vm.datePickerConfig},model:{value:(_vm.dateExpired),callback:function ($$v) {_vm.dateExpired=$$v},expression:"dateExpired"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Тип","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Тип","label-for":"new-offer-type"}},[_c('v-select',{attrs:{"id":"new-offer-type","placeholder":"Выберите тип предложения","state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.offerTypes},model:{value:(_vm.offerType),callback:function ($$v) {_vm.offerType=$$v},expression:"offerType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Мерчант","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Мерчант","label-for":"new-offer-merchant"}},[_c('v-select',{attrs:{"id":"new-offer-merchant","placeholder":"Введите название мерчанта","state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.sortedMerchants},on:{"search":_vm.getMerchants,"input":_vm.getBranches},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Пусто ")]}}],null,true),model:{value:(_vm.selectedMerchant),callback:function ($$v) {_vm.selectedMerchant=$$v},expression:"selectedMerchant"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Филиалы","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Филиалы","label-for":"new-offer-merchant-branch"}},[_c('v-select',{attrs:{"id":"new-offer-merchant-branch","placeholder":"Введите название филиала","state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","multiple":"","options":_vm.sortedBranches},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Пусто ")]}}],null,true),model:{value:(_vm.selectedBranches),callback:function ($$v) {_vm.selectedBranches=$$v},expression:"selectedBranches"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }